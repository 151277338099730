import { defineNuxtPlugin } from '#app'
import { useRouter } from 'vue-router'
import { watch, watchEffect } from 'vue'
import {useUserStore} from "~/stores/user";

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()
    const userStore = useUserStore()

    watch(
        () => router.currentRoute.value,
        (to, from) => {
        if (!!router.currentRoute.value.query['impersonate'] && userStore.isLoggedIn) {
            userStore.trigLogout([{impersonate: '1'}])
        }
    })
})
