import {getAxiosConfig} from "~/store/constants/api-url";

// Account
export function userConfirmedAccountData($axios) {
	return $axios.$post(
		`/users/confirmAccountData`,
		{ },
		getAxiosConfig()
	)
}

export function fetchAccount($axios, accountId) {
	return $axios.$get(`/accounts/${accountId}`, getAxiosConfig())
}

export function saveAccountData($axios, accountId, data) {
	if (!$axios || !accountId || !data) {
		return Promise.reject()
	}

	return $axios.$put(`/accounts/${accountId}`, data, getAxiosConfig())
}

// Accounts
export function fetchAccounts($axios) {
	return $axios.$get(`/accounts`, getAxiosConfig())
}

// Order Timeregistrations
export function getOrderTimeRegistrations({ accountId, orderId }, $axios) {
	if (!accountId || !orderId || !$axios) {
		return Promise.reject('Missing props')
	}

	return $axios.$get(
		`/accounts/${accountId}/orders/${orderId}/timeregistrations`,
		getAxiosConfig()
	)
}

export function postOrderTimeRegistrations(
	{ accountId, orderId, timeRegistration },
	$axios,
) {
	if (!accountId || !orderId || !timeRegistration || !$axios) {
		return Promise.reject('Missing props')
	}

	return $axios.$post(
		`/accounts/${accountId}/orders/${orderId}/timeregistrations`,
		timeRegistration,
		getAxiosConfig()
	)
}

export function putOrderTimeRegistrations(
	{ accountId, orderId, registrationId, timeRegistration },
	$axios
) {
	if (
		!accountId ||
		!orderId ||
		!registrationId ||
		!timeRegistration ||
		!$axios
	) {
		return Promise.reject('Missing props')
	}

	return $axios.$put(
		`/accounts/${accountId}/orders/${orderId}/timeregistrations/${registrationId}`,
		timeRegistration,
		getAxiosConfig()
	)
}

export function deleteOrderTimeRegistrations(
	{ accountId, orderId, registrationId },
	$axios
) {
	if (!accountId || !orderId || !registrationId || !$axios) {
		return Promise.reject('Missing props')
	}

	return $axios.$delete(
		`/accounts/${accountId}/orders/${orderId}/timeregistrations/${registrationId}`,
		getAxiosConfig()
	)
}
