/* tslint:disable */
/* eslint-disable */
/**
 * mjosenskogService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { UserModel } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm account data for users that shouldn\'t verify with BankID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmAccountData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/confirmAccountData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetAllUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get userId/ContactId of current logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetLoggedInUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/get-logged-in-user-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all new users since \"timestamp\" - #1335
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetNewUsers: async (timestamp: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('userGetNewUsers', 'timestamp', timestamp)
            const localVarPath = `/api/v1/users/import-new-users/{timestamp}`
                .replace(`{${"timestamp"}}`, encodeURIComponent(String(timestamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTimestampForTest: async (dateTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/import/timestamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateTime !== undefined) {
                localVarQueryParameter['dateTime'] = (dateTime as any instanceof Date) ?
                    (dateTime as any).toISOString() :
                    dateTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users modified since \"timestamp\"
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUpdatedUsers: async (timestamp: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('userGetUpdatedUsers', 'timestamp', timestamp)
            const localVarPath = `/api/v1/users/import/{timestamp}`
                .replace(`{${"timestamp"}}`, encodeURIComponent(String(timestamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users from email
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserFromEmail: async (email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/import/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserImage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGetUserImage', 'id', id)
            const localVarPath = `/api/v1/users/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users with index
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUsersWithId: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userGetUsersWithId', 'userId', userId)
            const localVarPath = `/api/v1/users/import/userId/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users with index
         * @param {number} i 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUsersWithIndex: async (i: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'i' is not null or undefined
            assertParamExists('userGetUsersWithIndex', 'i', i)
            const localVarPath = `/api/v1/users/import/index/{i}`
                .replace(`{${"i"}}`, encodeURIComponent(String(i)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm account data for users that shouldn\'t verify with BankID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userConfirmAccountData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userConfirmAccountData(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userConfirmAccountData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetAllUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetAllUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetAllUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get userId/ContactId of current logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetLoggedInUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetLoggedInUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetLoggedInUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all new users since \"timestamp\" - #1335
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetNewUsers(timestamp: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetNewUsers(timestamp, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetNewUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [dateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetTimestampForTest(dateTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetTimestampForTest(dateTime, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetTimestampForTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all users modified since \"timestamp\"
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUpdatedUsers(timestamp: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUpdatedUsers(timestamp, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetUpdatedUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get users from email
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserFromEmail(email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserFromEmail(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetUserFromEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get user image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserImage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserImage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetUserImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get users with index
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUsersWithId(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUsersWithId(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetUsersWithId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get users with index
         * @param {number} i 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUsersWithIndex(i: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUsersWithIndex(i, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userGetUsersWithIndex']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm account data for users that shouldn\'t verify with BankID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmAccountData(options?: any): AxiosPromise<void> {
            return localVarFp.userConfirmAccountData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetAllUsers(options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.userGetAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get userId/ContactId of current logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetLoggedInUser(options?: any): AxiosPromise<string> {
            return localVarFp.userGetLoggedInUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all new users since \"timestamp\" - #1335
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetNewUsers(timestamp: number, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.userGetNewUsers(timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTimestampForTest(dateTime?: string, options?: any): AxiosPromise<number> {
            return localVarFp.userGetTimestampForTest(dateTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users modified since \"timestamp\"
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUpdatedUsers(timestamp: number, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.userGetUpdatedUsers(timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users from email
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserFromEmail(email?: string, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.userGetUserFromEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserImage(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userGetUserImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users with index
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUsersWithId(userId: string, options?: any): AxiosPromise<UserModel> {
            return localVarFp.userGetUsersWithId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users with index
         * @param {number} i 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUsersWithIndex(i: number, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.userGetUsersWithIndex(i, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Confirm account data for users that shouldn\'t verify with BankID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userConfirmAccountData(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userConfirmAccountData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetAllUsers(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get userId/ContactId of current logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetLoggedInUser(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetLoggedInUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all new users since \"timestamp\" - #1335
     * @param {number} timestamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetNewUsers(timestamp: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetNewUsers(timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetTimestampForTest(dateTime?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetTimestampForTest(dateTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users modified since \"timestamp\"
     * @param {number} timestamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUpdatedUsers(timestamp: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUpdatedUsers(timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users from email
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserFromEmail(email?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserFromEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user image
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserImage(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users with index
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUsersWithId(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUsersWithId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users with index
     * @param {number} i 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUsersWithIndex(i: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUsersWithIndex(i, options).then((request) => request(this.axios, this.basePath));
    }
}

