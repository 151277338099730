import revive_payload_client_tegXmm0ALo from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vVAqrzT1A0 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ksAFPs09Hs from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UYbRQKFKLt from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2sAbsMKVKZ from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8wpcB3ZzuP from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mexqnTzXUY from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_L3sEn3xrn2 from "/home/vsts/work/1/s/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.0_vue@3.4.34/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_Mj536seT3h from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rollup@4._5e5sppsc75iaiu6wdzyc5lxazm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/home/vsts/work/1/s/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_7CkJahCY8Z from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.0_vue@3.4.34/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_LAxr9a41JQ from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.0_vue@3.4.34/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _1_application_insights_client_2isRgr4PXf from "/home/vsts/work/1/s/plugins/1.application-insights.client.ts";
import _2_axios_WdR72Mlp4v from "/home/vsts/work/1/s/plugins/2.axios.ts";
import _3_bank_id_axios_Osji7qm3jk from "/home/vsts/work/1/s/plugins/3.bank-id-axios.ts";
import componentUUID_juXswTyVuD from "/home/vsts/work/1/s/plugins/componentUUID.ts";
import highlight_client_IaNTAxQqnA from "/home/vsts/work/1/s/plugins/highlight.client.ts";
import i18n_client_gXssPVzz4q from "/home/vsts/work/1/s/plugins/i18n.client.ts";
import nullSafeTranslation_bHkfjW2WAU from "/home/vsts/work/1/s/plugins/nullSafeTranslation.ts";
import nuxt_confirm_client_Q1fsxkFAKj from "/home/vsts/work/1/s/plugins/nuxt-confirm.client.ts";
import nuxt_link_import_yzIAaKi1HN from "/home/vsts/work/1/s/plugins/nuxt-link-import.ts";
import router_watch_LdArCFqd7Z from "/home/vsts/work/1/s/plugins/router-watch.ts";
import sentry_logging_o4SBtmowK2 from "/home/vsts/work/1/s/plugins/sentry-logging.ts";
import vue_mask_Sy5Q0VdlQN from "/home/vsts/work/1/s/plugins/vue-mask.ts";
import vue_scrollto_lZXr206Htt from "/home/vsts/work/1/s/plugins/vue-scrollto.ts";
import vue_upload_EYBf6D2S5g from "/home/vsts/work/1/s/plugins/vue-upload.ts";
export default [
  revive_payload_client_tegXmm0ALo,
  unhead_vVAqrzT1A0,
  router_ksAFPs09Hs,
  payload_client_UYbRQKFKLt,
  navigation_repaint_client_2sAbsMKVKZ,
  check_outdated_build_client_8wpcB3ZzuP,
  chunk_reload_client_mexqnTzXUY,
  plugin_vue3_L3sEn3xrn2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Mj536seT3h,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_7CkJahCY8Z,
  i18n_LAxr9a41JQ,
  _1_application_insights_client_2isRgr4PXf,
  _2_axios_WdR72Mlp4v,
  _3_bank_id_axios_Osji7qm3jk,
  componentUUID_juXswTyVuD,
  highlight_client_IaNTAxQqnA,
  i18n_client_gXssPVzz4q,
  nullSafeTranslation_bHkfjW2WAU,
  nuxt_confirm_client_Q1fsxkFAKj,
  nuxt_link_import_yzIAaKi1HN,
  router_watch_LdArCFqd7Z,
  sentry_logging_o4SBtmowK2,
  vue_mask_Sy5Q0VdlQN,
  vue_scrollto_lZXr206Htt,
  vue_upload_EYBf6D2S5g
]