import {defineStore} from 'pinia'
import {CUSTOMER_ROLES, ROLE_FOREST_OWNER} from "~/store/constants/customer";
import {AccountApi} from "~/gen/openapi/portalService";

interface State {
	isFetching: boolean,
	timeLastFetch: any,
	items: Array<any>,
	primaryForestOwnerAccount: any
}

export const useAccountsStore = defineStore('accounts', {
  state: (): State => ({
	  isFetching: false,
	  timeLastFetch: null,
	  items: [],
	  primaryForestOwnerAccount: undefined
	}),
	getters: {
		roles(state) {
			const accounts: any[] = []
			// Role 1: Skogeier, Role 2: Entreprenør. Role 4: Accountant,
			// AccessLevel 1: Full (admin), AccessLevel 2: Limited.
			CUSTOMER_ROLES.forEach((role) => {
				const roleAccounts = state.items.filter(
					(account) => account.Role === role.id
				)

				if (roleAccounts.length) {
					accounts.push({
						accounts: roleAccounts,
						name: role.name,
						path: role.path,
						id: role.id,
					})
				}
			})

			return accounts
		},
		accountRole(state) {
			return (accountId: any) => {
				const account = state.items.find((account) => account.AccountId === accountId)
				return account ? account.Role : null
			}
		},
		// https://dev.azure.com/GlommenMjosen/Portaler%20og%20SBL-app/_workitems/edit/1538
		// Properties (and by extension - accounts) can be marked as "excluded" from the normal verification flow.
		userShouldVerify(state) {
			return state.primaryForestOwnerAccount?.IsExcludedFromVerification === false
		},
	},
	actions: {
		async fetch() {
			const {$axios, $config} = useNuxtApp()
			const accountApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios);
			this.timeLastFetch = Date.now()
			this.isFetching = true

			try {
				const accounts = (await accountApi.accountGetAccountsByCurrentUser()).data
				this.items = accounts
				const primaryForestOwnerAccountPermissionModel = accounts.find((account) => account.Role === ROLE_FOREST_OWNER && account.IsPrimaryAccount)
				if (primaryForestOwnerAccountPermissionModel) {
					// @ts-ignore
					this.primaryForestOwnerAccount = (await accountApi.accountGetAccount(primaryForestOwnerAccountPermissionModel.AccountId)).data
				}
			} catch (error) {
				console.error(error)
			} finally {
				this.isFetching = false
			}
		},
	}
})
