import {defineStore} from 'pinia'
import {AccountApi, type AccountBankIdModel, type AccountModel, type PropertiesContactModel} from "~/gen/openapi/portalService";
import {CUSTOMER_ROLES, CUSTOMER_TYPES} from "~/store/constants/customer";
import {useAccountsStore} from "~/stores/accounts";

interface State {
    activeAccount?: string | null,
    activeAccountParent?: string | null,
    contacts: Array<PropertiesContactModel>,
    isFetching: boolean,
    isFetchingContacts: boolean,
    isSaving: boolean,
    account: AccountModel
}

export const useAccountStore = defineStore('account', {
  state: (): State => ({
      // activeAccount can in some cases be an entrepreneur "team", and not an actual account.
      // in that case, activeAccountParent will be the actual accountId
      activeAccount: null,
      activeAccountParent: null,
      contacts: [],
      isFetching: false,
      isFetchingContacts: false,
      isSaving: false,
      account: {},
	}),
	getters: {
        accountantId(state) {
            return (state && state.account && state.account.AccountantId) || null
        },
        accountantName(state) {
            return (state && state.account && state.account.AccountantName) || null
        },
        accountData(state) {
            const accountsStore = useAccountsStore();
            const accountId = (state && state.account && state.account.Id) || null
            return (
                accountId &&
                accountsStore.items.find(
                    (account) => account.AccountId === accountId
                )
            )
        },
        accountId(state) {
            return (state && state.account && state.account.Id) || null
        },
        accountName() {
            const accountData = this.accountData
            return (
                (accountData &&
            // @ts-ignore
                    (accountData.EntrepreneurName || accountData.AccountName)) ||
                ''
            )
        },
        accountTeamName() {
            const accountData = this.accountData
            return accountData
                // @ts-ignore
                ? accountData.EntrepreneurName &&   // @ts-ignore
                accountData.AccountName &&  // @ts-ignore
                accountData.AccountName.indexOf(accountData.EntrepreneurName) === 0	// @ts-ignore
                    ? accountData.AccountName.substring(    // @ts-ignore
                        accountData.EntrepreneurName.length + 1
                    )   // @ts-ignore
                    : accountData.AccountName
                : ''
        },
        email(state) {
            return (state && state.account && state.account.Email) || ''
        },
        getBasePath(state) {
            const accountId = state.activeAccount
            if (!state.activeAccount) {
                return '/'
            }
            const accountsStore = useAccountsStore();
            const account = accountsStore.items.find(
                (item) => item.AccountId === accountId
            )
            if (account && account.Role) {
                const role = CUSTOMER_ROLES.find((role) => role.id === account.Role)
                return role ? `/${role.path}/${accountId}` : '/'
            }
            return '/'
        },
        hasAdminAccess() {
            const accountData = this.accountData

            // If access level is 1, or if user has another account with the same contractor ID and access level 1
            // @ts-ignore
            return accountData && accountData.AccessLevel === 1
        },
        hasRole: (state) => (roletype: keyof typeof CUSTOMER_TYPES) => {
            return (
                roletype in CUSTOMER_TYPES &&
                state.account &&
                    'CustomerType' in state.account &&
                // @ts-ignore
                CUSTOMER_TYPES[roletype].includes(state.account.CustomerType)
            )
        },
        isSilvicultureAccount(state) {
            const accountsStore = useAccountsStore();
            const accountId = state.activeAccount;
            const account = accountsStore.items.find((account) => account.AccountId === accountId)
            return account && account.Role === 2
        },

	},
	actions: {
        async fetch() {
            if (!this.activeAccount) {
                return
            }
            const {$axios, $config} = useNuxtApp()
            this.isFetching = true
            const accountApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios);

            try {
                const accountId = this.activeAccountParent || this.activeAccount
                if (accountId) {
                    this.account = (await accountApi.accountGetAccount(accountId)).data
                }
            } catch (error) {
                this.account = {}
                console.error(error)
            } finally {
                this.isFetching = false
            }
        },
        async fetchContacts() {
            if (!this.activeAccount) {
                return
            }
            const {$axios, $config} = useNuxtApp()
            this.isFetchingContacts = true
            const accountApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios);

            try {
                if (this.accountId) {
                    this.contacts = (await accountApi.accountGetAccountPropertiesContacts(this.accountId)).data
                } else {
                    this.contacts = []
                }
            } catch (error) {
                this.contacts = []
                console.error(error)
            } finally {
                this.isFetchingContacts = false
            }
        },
        reset() {
            this.activeAccount = null
            this.account = {}
            this.contacts = []
        },
        save(data: AccountBankIdModel) {
            if (!this.activeAccount) {
                return
            }

            const {$axios, $config} = useNuxtApp()
            this.isSaving = true
            const accountApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios);

            return new Promise((resolve, reject) => {
                try {
                   const response = accountApi.accountUpdateAccountBankId(this.activeAccount!, data)
                    resolve(response)
                } catch (error) {
                    this.isSaving = false
                    console.error(error)
                    reject(error)
                } finally {
                    this.isSaving = false
                }
            })
        },
        async setActive(accountId: string): Promise<void> {
            if (
                this.activeAccount &&
                accountId === this.activeAccount &&
                this.account
            ) {
                return
            }

            // If the selected account is an entrepreneur team, the account.AccountId will not be valid.
            // Instead, the valid AccountId sits in the field EntrepreneurId, and should be used to fetch
            // account details.


            const accountsStore = useAccountsStore();
            const allAccounts = accountsStore.items
            const account = allAccounts.find((account) => account.AccountId === accountId)
            if (account && account.EntrepreneurId) {
                this.activeAccountParent = account.EntrepreneurId
            } else {
                this.activeAccountParent = null
            }
            this.activeAccount = accountId
            await Promise.allSettled([this.fetch().then(() => this.fetchContacts())])
        },

		resetEnvironmentalAgreementId(id: string) {
					this.account.PendingEnvironmentalAgreementId = this.account.PendingEnvironmentalAgreementId?.replace(id, '')
		}
	}
})
